import React, { useEffect, useState } from 'react';
// import AnimateImages from "@its2easy/animate-images";

function Home() {
    let value = "Contact Us";
    // let isActive = false;
    const images = [
        //start Fire
        '/assets/images/incoming/ftf1.jpeg',
        '/assets/images/incoming/ftf1.jpeg',
        '/assets/images/incoming/ftf1.jpeg',
        '/assets/images/incoming/ftf2.jpeg',
        '/assets/images/incoming/ftf2.jpeg',
        '/assets/images/incoming/ftf2.jpeg',
        '/assets/images/incoming/ftf3.jpg',
        '/assets/images/incoming/ftf3.jpg',
        '/assets/images/incoming/ftf3.jpg',
        '/assets/images/incoming/ftf4.jpg',
        '/assets/images/incoming/ftf4.jpg',
        '/assets/images/incoming/ftf4.jpg',
        '/assets/images/incoming/ftf5.jpg',
        '/assets/images/incoming/ftf5.jpg',
        '/assets/images/incoming/ftf5.jpg',
        '/assets/images/incoming/ftf6.jpg',
        '/assets/images/incoming/ftf6.jpg',
        '/assets/images/incoming/ftf6.jpg',
        //End fire
        //start Fire
        '/assets/images/incoming/ftf1.jpeg',
        '/assets/images/incoming/ftf1.jpeg',
        '/assets/images/incoming/ftf1.jpeg',
        '/assets/images/incoming/ftf2.jpeg',
        '/assets/images/incoming/ftf2.jpeg',
        '/assets/images/incoming/ftf2.jpeg',
        '/assets/images/incoming/ftf3.jpg',
        '/assets/images/incoming/ftf3.jpg',
        '/assets/images/incoming/ftf3.jpg',
        '/assets/images/incoming/ftf4.jpg',
        '/assets/images/incoming/ftf4.jpg',
        '/assets/images/incoming/ftf4.jpg',
        '/assets/images/incoming/ftf5.jpg',
        '/assets/images/incoming/ftf5.jpg',
        '/assets/images/incoming/ftf5.jpg',
        '/assets/images/incoming/ftf6.jpg',
        '/assets/images/incoming/ftf6.jpg',
        '/assets/images/incoming/ftf6.jpg',
        //End fire
        //start Fire
        '/assets/images/incoming/ftf1.jpeg',
        '/assets/images/incoming/ftf1.jpeg',
        '/assets/images/incoming/ftf1.jpeg',
        '/assets/images/incoming/ftf2.jpeg',
        '/assets/images/incoming/ftf2.jpeg',
        '/assets/images/incoming/ftf2.jpeg',
        '/assets/images/incoming/ftf3.jpg',
        '/assets/images/incoming/ftf3.jpg',
        '/assets/images/incoming/ftf3.jpg',
        '/assets/images/incoming/ftf4.jpg',
        '/assets/images/incoming/ftf4.jpg',
        '/assets/images/incoming/ftf4.jpg',
        '/assets/images/incoming/ftf5.jpg',
        '/assets/images/incoming/ftf5.jpg',
        '/assets/images/incoming/ftf5.jpg',
        '/assets/images/incoming/ftf6.jpg',
        '/assets/images/incoming/ftf6.jpg',
        '/assets/images/incoming/ftf6.jpg',
        //End fire
        //start Fire
        '/assets/images/incoming/ftf1.jpeg',
        '/assets/images/incoming/ftf1.jpeg',
        '/assets/images/incoming/ftf1.jpeg',
        '/assets/images/incoming/ftf2.jpeg',
        '/assets/images/incoming/ftf2.jpeg',
        '/assets/images/incoming/ftf2.jpeg',
        '/assets/images/incoming/ftf3.jpg',
        '/assets/images/incoming/ftf3.jpg',
        '/assets/images/incoming/ftf3.jpg',
        '/assets/images/incoming/ftf4.jpg',
        '/assets/images/incoming/ftf4.jpg',
        '/assets/images/incoming/ftf4.jpg',
        '/assets/images/incoming/ftf5.jpg',
        '/assets/images/incoming/ftf5.jpg',
        '/assets/images/incoming/ftf5.jpg',
        '/assets/images/incoming/ftf6.jpg',
        '/assets/images/incoming/ftf6.jpg',
        '/assets/images/incoming/ftf6.jpg',
        //End fire
        //start Fire
        '/assets/images/incoming/ftf1.jpeg',
        '/assets/images/incoming/ftf1.jpeg',
        '/assets/images/incoming/ftf1.jpeg',
        '/assets/images/incoming/ftf2.jpeg',
        '/assets/images/incoming/ftf2.jpeg',
        '/assets/images/incoming/ftf2.jpeg',
        '/assets/images/incoming/ftf3.jpg',
        '/assets/images/incoming/ftf3.jpg',
        '/assets/images/incoming/ftf3.jpg',
        '/assets/images/incoming/ftf4.jpg',
        '/assets/images/incoming/ftf4.jpg',
        '/assets/images/incoming/ftf4.jpg',
        '/assets/images/incoming/ftf5.jpg',
        '/assets/images/incoming/ftf5.jpg',
        '/assets/images/incoming/ftf5.jpg',
        '/assets/images/incoming/ftf6.jpg',
        '/assets/images/incoming/ftf6.jpg',
        '/assets/images/incoming/ftf6.jpg',
        //End fire
        '/assets/images/incoming/ftf7.jpg',
        '/assets/images/incoming/ftf7.jpg',
        '/assets/images/incoming/ftf7.jpg',
        '/assets/images/incoming/ftf7.jpg',
        '/assets/images/incoming/ftf7.jpg',
        '/assets/images/incoming/ftf7.jpg',
        '/assets/images/incoming/ftf7.jpg',
        '/assets/images/incoming/ftf7.jpg',
        '/assets/images/incoming/ftf8.jpg',
        '/assets/images/incoming/ftf8.jpg',
        '/assets/images/incoming/ftf9.jpg',
        '/assets/images/incoming/ftf10.jpg',
        '/assets/images/incoming/ftf11.jpg',
        '/assets/images/incoming/ftf12.jpg',
        '/assets/images/incoming/ftf13.jpg',
        '/assets/images/incoming/ftf14.jpg',
        '/assets/images/incoming/ftf14.jpg',
        '/assets/images/incoming/ftf14.jpg',
        '/assets/images/incoming/ftf14.jpg',
        '/assets/images/incoming/ftf14.jpg',
        '/assets/images/incoming/ftf14.jpg',
        '/assets/images/incoming/ftf14.jpg',
        '/assets/images/incoming/ftf15.jpg',
        '/assets/images/incoming/ftf16.jpg',
        '/assets/images/incoming/ftf17.jpg',
        '/assets/images/incoming/ftf18.jpg',
        '/assets/images/incoming/ftf19.jpg',
        '/assets/images/incoming/ftf20.jpg',
        '/assets/images/incoming/ftf21.jpeg',
        '/assets/images/incoming/ftf21.jpeg',
        '/assets/images/incoming/ftf21.jpeg',
        '/assets/images/incoming/ftf21.jpeg',
        '/assets/images/incoming/ftf21.jpeg',
        '/assets/images/incoming/ftf21.jpeg',
        '/assets/images/incoming/ftf21.jpeg',
        '/assets/images/incoming/ftf21.jpeg',
        '/assets/images/incoming/ftf21.jpeg',
        '/assets/images/incoming/ftf21.jpeg',
        '/assets/images/incoming/ftf21.jpeg',
        '/assets/images/incoming/ftf21.jpeg',
        '/assets/images/incoming/ftf21.jpeg',
    ];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const [text, setText] = useState(value);

    let interval = null;

    // const handleMouseOver = (event) => {
    const animateText = (event) => {
        let iteration = 0;
        clearInterval(interval);

        interval = setInterval(() => {
            setText((prevText) =>
                value
                    .split('')
                    .map((letter, index) => {
                        if (index < iteration) {
                            return value[index];
                        }
                        return letters[Math.floor(Math.random() * 26)];
                    })
                    .join('')
            );

            if (iteration >= value.length) {
                clearInterval(interval);
            }

            iteration += 1 / 3;
        }, 50);
    };

    useEffect(() => {
        const loadImages = async () => {
            const promises = images.map(
                (src) => new Promise((resolve) => {
                    const img = new Image();
                    img.src = src;
                    img.onload = resolve;
                }))
            // );

            await Promise.all(promises);
            setIsLoaded(true); // Set loaded state to true when all images are loaded
        };

        loadImages();

    }, [images]);

    useEffect(() => {


        if (!isLoaded) return;

        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => {
                // Stop if the last image is reached
                if (prevIndex === images.length - 1) {
                    clearInterval(intervalId);
                    // isActive = true;
                    setIsActive(true);
                    animateText();
                    return prevIndex; // Stay on the last image
                }
                return (prevIndex + 1) % images.length;
            });
        }, 50); // Change image every 300ms

        return () => clearInterval(intervalId);
    }, [isLoaded, images.length]);






    return (
        <>
            <div className="slider">
                <img
                    src={images[currentIndex]}
                    alt={`Slide ${currentIndex}`}
                    className="slide"
                />
            </div>
            <div>
                <a href="mailto:hello@revox.io">
                    <label className={`contactUs ${isActive ? 'active' : 'not-active'}`}
                        // <label className="contactUs"
                        // onMouseOver={handleMouseOver}
                        data-value={value} >{text}</label>
                </a>
            </div>
        </>
    )

}

export default Home;